import styled from 'styled-components';
import Container from './Container';

const Content = styled.div`
    height : 500px;
    width : 100%;
    margin-top : 5rem;
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;

    @media (max-width: 768px) {
        margin-top : 0rem;
    }
`;

const Head = styled.h1`
    font-size : 3rem;
    font-weight : 800;
    text-align : center;
    
    @media (max-width: 768px) {
        font-size : 1.6rem;
        font-weight : 800;
    }
`;

const Description = styled.span`
    font-size : 1.7rem;
    font-weight : 400;
    text-align : center;

    @media (max-width: 768px) {
        font-size : 1.2rem;
        font-weight : 400;
    }
`;

const BarContainer = styled.div`
    display : flex;
    align-items : center;
    justify-content : center;
    width : 100%;
    margin-top: 3rem;

    @media (max-width: 768px) {
        width : 90%;
    }
`;

const BarEquality = styled.span`
    flex : 0.5;
    text-align : center;
    font-size : 4rem;
    font-weight : 800;

    @media (max-width: 768px) {
        flex : 0.2;
        font-size : 2rem;
        font-weight : 800;
    }
`;

const Bar = styled.div`
    display : flex;
    flex-direction : row;
    align-items : center;
    justify-content : space-between;
    flex : .3;
    background-color : white;
    text-decoration : none;
    font-size : 1.8rem;
    color : white;
    font-weight : 800;
    letter-spacing: .01rem;
    padding-top : 1rem;
    padding-bottom : 1rem;

    padding-left : 4rem;
    padding-right : 4rem;
    border-radius : 3rem;
    box-shadow : 0.2rem 0.2rem 0 #7d6c60;
    text-align: center;
    border : .1rem solid #b8aa9f;

    @media (max-width: 768px) {
        padding-top : 1rem;
        padding-bottom : 1rem;
    
        padding-left : 1.2rem;
        padding-right : 1.2rem;
    }
`;

const BarImg = styled.img`
    height : 40px;
    @media (max-width: 768px) {
        height : 25px;
    }
`;

const BarText = styled.span`
    font-size : 2rem;
    font-weight : 600;
    color : black;

    @media (max-width: 768px) {
        font-size : 0.8rem;
        font-weight : 500;
    }
`;

const Explanation = () => {
    return (
        <Container>
            <Content>
                <Head>
                    Simplified DEX <br/>
                    on top of StarkNET
                </Head>
                <Description>
                    Same experience like Uniswap & Pancakeswap
                </Description>

            </Content>
        </Container>
    )
}


export default Explanation;