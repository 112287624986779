import styled from 'styled-components';
import Container from './Container';

const Content = styled.div`
    height : 400px;
    background-color : #00FFAB;
    display : flex;
    flex-direction : row;
    align-items : center;
    justify-content : space-between;
    flex : .3;


    text-decoration : none;
    font-size : 1.8rem;

    font-weight : 800;
    letter-spacing: .01rem;
    padding-top : 1rem;
    padding-bottom : 1rem;

    padding-left : 4rem;
    padding-right : 4rem;
    border-radius : 3rem;
    box-shadow : 0.2rem 0.2rem 0 #7d6c60;
    border : .1rem solid #b8aa9f;

    @media (max-width: 768px) {
        flex-direction : column-reverse;
    }
`;

const Graph = styled.div`
    width : 45%;
    height : 100%;
    display : flex;
    align-items : center;
    justify-content : center;

    @media (max-width: 768px) {
        width: 100%;
        height : 40%;
        flex : 1;
    }
`;

const GraphImage = styled.img`
    height: 80%;
`;

const DescriptionContent = styled.div`
    width : 45%;

    @media (max-width: 768px) {
        width : 100%;
        flex: 1;
    }
`;
const DescriptionHead = styled.h1`
    font-size : 2rem;
    font-weight: 700;
    line-height : 2.1rem;

    @media (max-width: 768px) {
            font-size : 1.1rem;
        font-weight: 800;
        line-height : 2rem;
    }
`;
const DescriptionText = styled.span`
    font-size : 1.1rem;
    font-weight : 600;
    line-height : 1rem;

    @media (max-width: 768px) {
        font-size : 0.8rem;
        font-weight : 600;
    }
`;

export const SampleTwo = () => {
    return (
        <Container>
            <Content>
                <Graph>
                    <GraphImage src="./sample1.png"/>
                </Graph>

                <DescriptionContent>
                    <DescriptionHead>
                    Lending & Borrowing
                    </DescriptionHead>
                    <DescriptionText>
                    Lend & Borrow any supported currencies on StarkDEX
                    </DescriptionText>
                </DescriptionContent>
            </Content>
        </Container>
    )
}

export const SampleOne = () => {
    return (
        <Container>
            <Content style={{backgroundColor : 'white'}}> 
                <DescriptionContent>
                        <DescriptionHead>
                        Earn STARK
                            with yield farming
                        </DescriptionHead>
                        <DescriptionText>
                        Provide liquidity and earn high yield-farming APYs 
                        </DescriptionText>
                    </DescriptionContent>
                <Graph>
                    <GraphImage src="./sample2.png"/>
                </Graph>
            </Content>
        </Container>
    )
}

export const SampleThree = () => {
    return (
        <Container>
            <Content style={{backgroundColor : 'white'}}> 
                <DescriptionContent>
                        <DescriptionHead>
                        Participate in the Governance
                        </DescriptionHead>
                        <DescriptionText>
                        STARK holders can participate in the decentralized governance and earn protocol dividend.
                        </DescriptionText>
                </DescriptionContent>
                <Graph>
                    <GraphImage src="./sample3.png"/>
                </Graph>
            </Content>
        </Container>
    )
}