import styled from "styled-components";
import { BrandButton } from "./Buttons";

const Background = styled.div`
    display : flex;
    justify-content : center;
    background-color : rgba(0, 255, 171, .5);
    width : 100%;
`

const Content = styled.div`
    display : flex;
    flex-direction : column;
    align-items : center;
    width : 100%;
    max-width: 768px;
    @media (max-width: 768px) {
        width : 768px;
    }
    padding-top : 4.6rem;
`;


const Slogan = styled.span`
    font-weight : 600;
    font-size : 3rem;
    text-align : center;
    line-height : 5rem;
    letter-spacing : -0.1rem;

    @media (max-width: 768px) {
        font-size : 1.3rem;
        line-height : 2.2rem;
    }
`;

const BarContent = styled.div`
    display : flex;
    position : relative;
    flex-direction : row;
    justify-content : space-between;
    height : 12rem;
    width : 90%;
    transform: translateY(4rem);

    @media (max-width: 768px) {
        flex-direction : column;
        transform: translateY(4rem);
    }
`

const Step = styled.div`
    display : flex;
    align-items : center;
    justify-content : center;
    box-shadow: 0.4rem 0.4rem 0 #a59e9e;
    border: 0.2rem solid #a59e9e;
    background: #f3ece6;
    height : 100%;
    border-radius : 2rem;
    width : 30%;

    @media (max-width: 768px) {
        width : 100%;
        margin-bottom : 1rem;
        box-shadow: 0.2rem 0.2rem 0 #a59e9e;
        border: 0.1rem solid #a59e9e;
    }
`

const StepCount = styled.span`
    font-size : 2rem;
    font-weight : 600;

    @media (max-width: 768px) {
        font-size : 1.4rem;
        font-weight : 600;
    }
`;

const StepContent = styled.div`
    width : 80%;
    height : 80%;
    display : flex;
    flex-direction : column;
    justify-content : space-around;

    @media (max-width: 768px) {
        flex-direction : row;
        align-items : center;
        justify-content : space-between;
    }
`

const StepTag = styled.h1`
    font-size : 2rem;
    font-weight : 700;

    @media (max-width: 768px) {
        font-size : 1.1rem;
        font-weight : 800;
    }
`;

const StepDesc = styled.span`
    font-size : 1.2rem;
    font-weight : 500;
    @media (max-width: 768px) {
        font-size : 0.8rem;
        font-weight : 600;
    }
`
const Brand = () => {
    return (
        <Background>
            <Content>
                <Slogan>
                Trade & Earn with <br/>
                    <strong style={{backgroundColor : 'white', padding : '5px'}}>StarkDEX</strong> on StarkNET
                </Slogan>
                <BrandButton  text="Coming Soon" link="#"/>
                <BarContent>
                    <Step>
                        <StepContent>
                            <StepCount>
                                1
                            </StepCount>
                            <StepTag>
                                Trade
                            </StepTag>
                            <StepDesc>
                                on StarkNET
                            </StepDesc>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepContent>
                            <StepCount>
                                2
                            </StepCount>
                            <StepTag>
                                Earn
                            </StepTag>
                            <StepDesc>
                                Passive income
                            </StepDesc>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepContent>
                            <StepCount>
                                3
                            </StepCount>
                            <StepTag>
                                Bridge
                            </StepTag>
                            <StepDesc>
                                with ZK security
                            </StepDesc>
                        </StepContent>
                    </Step>
                </BarContent>
            </Content>
        </Background>
    )
}

export default Brand;