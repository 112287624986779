import styled from 'styled-components';
import Container from './Container';

const Content = styled.div`
    display : flex;
    flex-direction : column;
    text-align : center;
    padding-bottom : 3rem;

`;

const Header = styled.h1`
    font-size : 3.5rem;
    font-weight : 700;

    @media (max-width: 768px) {
        font-size : 2rem;
        font-weight : 700;
    }
`;

const Description = styled.span`
    font-size : 1.7rem;
    font-weight : 400;
    letter-spacing : -.05rem;
    padding-bottom : 3rem;

    @media (max-width: 768px) {
        font-size : 1.2rem;
        font-weight : 400;
        letter-spacing : -.05rem;
    }
`;

const Graph = styled.img`
    height : 600px;
    @media (max-width: 768px) {
        width : 100%;
        height : 250px;
    }
`;


const Architecture = () => {
    return (
        <Container>
            <Content>
                <Header>
                Architecture
                </Header>
                <Description>
                Every stakeholder is rewarded properly for their contributions to the system
                </Description>
                <Graph src="./diagram.svg">
                </Graph>
            </Content>
        </Container>
    )
}

export default Architecture;