import React from 'react';
import styled from 'styled-components';
import { LinkButton } from './Buttons';

const Container = styled.div`
    display : flex;
    width : 100%;
    height : 5.2rem;
    align-items : center;
    justify-content : center;

    @media (max-width: 768px) {
        height : 3.6rem;
    }
`

const Content = styled.div`
    display:flex;
    flex-direction : row;
    width : 100%;
    max-width: 900px;
    justify-content : space-between;
    align-items : center;

    @media (max-width: 768px) {
        width : 90%;
    }
`

const Brand = styled.div`
    color : #2C272E;
    font-weight : 700;
    font-size : 1.25rem;
    flex : 1;

    @media (max-width: 768px) {
        font-size : 1rem;
    }
`

const Links = styled.div`
    display : flex;
    flex : 0.4;
    justify-content : space-between;
    align-items : center;

    @media (max-width: 768px) {
        flex : 1;
    }
`

const LinkIcon = styled.a`
    display : flex;
    align-items : center;
    justify-content : center;

    cursor : pointer;
`;

const LinkImg = styled.img`
    height : 30px;

    @media (max-width: 768px) {
        height : 20px;
    }
`;

const Link = styled.a`
    font-size : 1rem;
    font-weight : 600;

    text-decoration : none;
    color : black;

    @media (max-width: 768px) {
        font-size : 0.7rem;
        font-weight : 600;
    }
`;



const Header = () => {
    return (
        <Container>
            <Content>
                <Brand>
                StarkDEX
                </Brand>
                <Links>
                    <LinkIcon href="https://medium.org">
                        <LinkImg src="./medium.svg" />
                    </LinkIcon>
                    <LinkIcon href="https://telegram">
                        <LinkImg src="./telegram.svg"  />
                    </LinkIcon>
                    <Link href="https://docs.starkdex.finance">
                    Docs
                    </Link>
                    <LinkButton text="Soon" link="#"/>
                </Links>
            </Content>
        </Container>
    )
}

export default Header;