import styled from 'styled-components';

const Container = styled.div`
    width : 100%;
    height : 250px;
    background-color : #D4F6CC;
    margin-top : 50px;
    display : flex;
    align-items : center;
    justify-content : center;

    @media (max-width: 768px) {
        height : 100px;
    }
`;

const Content = styled.div`
    width : 100%;
    max-width : 900px;
    display : flex;
    flex-direction : row;
    justify-content : space-between;
    align-items : center;
    @media (max-width: 768px) {
        width : 90%;
    }
`;

const Brand = styled.div`
    @media (max-width: 768px) {
        font-size : 0.75rem;
        font-weight : 600;
    }
`;

const Contact = styled.div`
    @media (max-width: 768px) {
        font-size : 0.5rem;
    }
`;

const Footer = () => {
    return (
        <Container>
            <Content>
                <Brand>
                    StarkDEX Finance
                </Brand>

                <Contact>
                © 2022 StarkDEX Finance. All rights reserved
                </Contact>
            </Content>
        </Container>
    )
}

export default Footer;