import Header from './components/Header';
import Brand from './components/Brand';
import Container from './components/Container'
import Explanation from './components/Explanation';
import { SampleOne, SampleTwo, SampleThree } from './components/Samples';
import Architecture from './components/Architecture';
import Footer from './components/Footer';


function App() {
  return (
    <div>
      <Header />
      <Brand />
      
      <Explanation />
      <SampleOne />
      <SampleTwo />
      <SampleThree />

      <Footer />
    </div>
  );
}

export default App;
