import styled from 'styled-components';

const ContainerEl = styled.div`
    display: flex;
    width : 100%;

    justify-content : center;
    margin-top : 5rem;

    @media (max-width: 768px) {
        margin-top : 2rem;
    }
`;

const Content = styled.div`
    max-width : 900px;
    width : 100%;
    @media (max-width: 768px) {
        width : 90%;
    }
`;

const Container = (props) => {
    return (
        <ContainerEl>
            <Content>
                {props.children}
            </Content>
        </ContainerEl>
    )
}

export default Container;