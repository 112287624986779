import styled from "styled-components";

const AppButton = styled.a`
    background : #b8aa9f;
    text-decoration : none;
    font-size : 1.2rem;
    color : white;
    font-weight : 600;
    letter-spacing: .01rem;
    padding : 0.36rem 1.2rem;
    border-radius : 3rem;
    box-shadow : 0.1rem 0.1rem 0 #7d6c60;
    text-align:center;
    border : .1rem solid #7d6c60;
    height : 1.4rem;
    width : 3.5rem;

    &:hover {
        box-shadow : 0.2rem 0.2rem 0 #7d6c60;
    }

    @media (max-width: 768px) {
        height : 0.8rem;
        width : 1rem;
        font-size : 0.6rem;
    }
`

const Button = styled.div`
    display : flex;
    flex-direction : row;
    align-items : center;
    justify-content : center;
    background-color : white;
    text-decoration : none;
    font-size : 1.8rem;
    color : white;
    font-weight : 800;
    letter-spacing: .01rem;
    padding : 0.36rem 1.2rem;
    border-radius : 3rem;
    box-shadow : 0.2rem 0.2rem 0 #7d6c60;
    text-align: center;
    border : .1rem solid #b8aa9f;
    height : 2.4rem;
    width : 14.4rem;
    margin-top: 4rem;

    cursor : pointer;

    &:hover {
        box-shadow : 0.4rem 0.4rem 0 #7d6c60;
    }
`

export const LinkButton = ({text, link}) => {
    return (
        <AppButton href={link} style={{color : 'white'}}>
            { text }
        </AppButton>
    )
}

export const BrandButton = ({text, link}) => {
    return (
        <Button href={link} style={{color : 'black'}}>
            { text }
        </Button>
    )
}